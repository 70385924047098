import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { RoadMapStateService } from '../service/roadmap-state.service';
import { take, takeUntil } from "rxjs/operators";
import { ModalService } from 'incom-component-library';
import { CreateProjectComponent } from './Modal/createProject.modal';
import { StartProjectComponent } from './Modal/startProject.modal';
import { Project } from "../Timeline/Project";
import { Subject } from "rxjs"
import { AuthenticationService } from 'incom-login';
@Component({
  selector: 'app-pipeline',
  templateUrl: './pipeline.component.html',
  styleUrls: ['./pipeline.component.css']
})
export class PipelineComponent implements OnInit, OnDestroy {
  overlayStyling: string[] = [
    "incom-modal-overlay"
  ];
  notifier = new Subject();
  constructor(private roadmapStateService: RoadMapStateService, private modalService: ModalService, private authenticationService: AuthenticationService) {

  }
  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }
  ngOnInit(): void {
    this.authenticationService.currentUser.pipe(takeUntil(this.notifier)).subscribe(x => {
      this.isLoggedIn = !!x;
    })
    this.roadmapStateService.setProjectType(1);
    this.roadmapStateService.getProjects().pipe(takeUntil(this.notifier)).subscribe(x => {
      this.projects = x.sort((a, b) => (a.priority < b.priority) ? 1 : -1);
      this.projects.forEach(project => {
        if (project.productOwner) {
          project.projectPO = "PO: " + project.productOwner;
          if (project.customerProductOwner) {
            project.projectPO += " \n";
          }
        }
        if (project.customerProductOwner) {
          project.projectPO += "KPO: " + project.customerProductOwner;
        }
      });
    });
  }
  createProject() {
    const project = ({
      name: "",
      milestones: [],
      priority: this.projects.length + 1,
    } as Project)
    const onSuccess = () => {
      this.saveProject(project);
    };
    this.modalService.open(CreateProjectComponent, {
      project: project,
      isEditing: false
    }, undefined, onSuccess, this.overlayStyling);

  }
  editProject(project) {
    const onSuccess = () => this.roadmapStateService.updateProject(project)
    this.modalService.open(CreateProjectComponent, {
      project: project,
      isEditing: true
    }, undefined, onSuccess, this.overlayStyling);
  }
  saveProject(project: any) {
    this.roadmapStateService.createProject(project);
  }
  prioUp(index: number) {
    var previousItem = this.projects[index - 1];
    var item = this.projects[index];
    previousItem.priority = item.priority;
    item.priority = previousItem.priority;
    this.projects[index - 1] = item;
    this.projects[index] = previousItem;
    this.roadmapStateService.updateProject(item);
    this.roadmapStateService.updateProject(previousItem);
  }
  prioDown(index: number) {
    var previousItem = this.projects[index + 1];
    var item = this.projects[index];
    previousItem.priority = item.priority;
    item.priority = previousItem.priority;
    this.projects[index + 1] = item;
    this.projects[index] = previousItem;
    this.roadmapStateService.updateProject(item);
    this.roadmapStateService.updateProject(previousItem);
  }
  startProject(project) {
    if (this.isLoggedIn) {
      const milestone = ({
        name: "",
        startDate: new Date(),
        projectId: project.uId
      });
      const onSuccess = () => {

        this.addMilestoneToProject(milestone, project);
      }
      this.modalService.open(StartProjectComponent, milestone, undefined, onSuccess, this.overlayStyling);
    }
  }
  addMilestoneToProject(milestone, project) {
    this.roadmapStateService.addMilestoneToProject(milestone, project.uId);
  }
  drop(event: CdkDragDrop<string[]>) {
    if (this.isLoggedIn) {
      moveItemInArray(this.projects, event.previousIndex, event.currentIndex);
      let indexer = 0;
      for (var i = this.projects.length; i > 0; i--) {
        const project = this.projects[indexer];
        if (project.priority != i) {
          project.priority = i;
          this.roadmapStateService.updateProject(project);
        }
        indexer++;

      }
    }


  }
  projects: Project[] = [];
  isLoggedIn: boolean;
}
