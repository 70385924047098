import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class RoadMapHttpService {
  deleteMilestone(uId) {
    return this.httpClient.delete<any>(`/Roadmap/DeleteMilestone/${uId}`).pipe(map(val => val.data));
  }

  constructor(private httpClient: HttpClient) { }
  addMilestoneToProject(milestone, id: string) {
    return this.httpClient.post<any>(`/Roadmap/AddMilestoneToProject/${id}`, milestone).pipe(map(val => val.data));
  }
  createProject(project) {
    console.log(project)
    return this.httpClient.post<any>(`/Roadmap/CreateProject`, project).pipe(map(val => val.data));;
  }
  getMileStonesForProject(projectUId) {
    return this.httpClient.get<any>(`/Roadmap/GetMileStonesForProject?projectUId=${projectUId}`).pipe(map(val => val.data));;
  }
  getProjects(projectType) {
    return this.httpClient.get<any>(`/Roadmap/GetProjects?projectType=${projectType}`,).pipe(map(val => val.data));;
  }
  updateMilestone(milestone) {
    return this.httpClient.post<any>(`/Roadmap/updateMilestone`, milestone).pipe(map(val => val.data));;
  }
  updateProject(project) {
    return this.httpClient.post<any>(`/Roadmap/UpdateProject`, project).pipe(map(val => val.data));;
  }
  deleteProject(projectUid) {
    return this.httpClient.delete<any>(`/Roadmap/DeleteProject/${projectUid}`).pipe(map(val => val.data));
  }
}
