import { Component, HostListener, Input, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { RoadMapHelperService } from "../../service/roadmap-helper.service";
import { Project } from "../Project";
import { DatePipe } from "@angular/common";
import { EditMilestoneComponent } from "../../milestone/editMilestone.modal";
import { RoadMapStateService } from "../../service/roadmap-state.service";
import { ModalService } from "incom-component-library";

import { CreateMilestoneComponent } from "../../milestone/createMilestone.modal";
import { AuthenticationService } from "incom-login";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { CreateProjectComponent } from '../../pipeline/Modal/createProject.modal';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit, OnDestroy {
  overlayStyling: string[] = [
    "incom-modal-overlay"
  ];
  @Input("project") project: Project;
  @Input("viewStart") viewStart: Date;
  @Input("viewEnd") viewEnd: Date;
  @Input("cellWidth") cellWidth: number;
  @Input("enumDays") enumDays: any[];
  @Input("currTime") currTime: number;
  @Input("viewPeriod") viewPeriod: number;
  today = Date.now();

  linesFill: any = [];			// Empty the lines filling map
  resize$ = new Subject<void>();
  projectPO: string;
  showMilestones: boolean = false;
  projectHeight: {};
  eventHeight = 20;
  eventMargin = 10;
  maxLinesOfEvents = 5;
  projectType: number;
  notifier = new Subject();
  constructor(private dateFilter: DatePipe, private roadmapService: RoadMapStateService, private modalService: ModalService, private roadMapHelperService: RoadMapHelperService, public element: ElementRef, private authenticationService: AuthenticationService) {

  }
  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }
  ngOnInit(): void {
    if (this.project.productOwner) {
      this.projectPO = "PO: " + this.project.productOwner;
      if (this.project.customerProductOwner) {
        this.projectPO += " \n";
      }
    }
    if (this.project.customerProductOwner) {
      this.projectPO += "KPO: " + this.project.customerProductOwner;
    }
    this.project.renderedEvents = [];
    this.roadmapService.renderEvent$.pipe(takeUntil(this.notifier)).subscribe(x => {
      this.project.renderedEvents = [];
      setTimeout(() => {
        this.renderView()
      }, 50);
    });
    this.authenticationService.currentUser.pipe(takeUntil(this.notifier)).subscribe(x => {
      this.isLoggedIn = !!x;
    })
    this.projectType = this.roadmapService.projectType;
    window.dispatchEvent(new Event('resize'));
    this.resize$
      .pipe(
        debounceTime(200)
      )
      .subscribe(() => {
        this.renderView();
      })
  }

  @HostListener("window:resize", ["$event.target"])
  onResize(): void {
    this.resize$.next();
  }
  triggerRenderEvent() {
    this.roadmapService.renderEvent$.next(true);
  }
  renderView() {
    if (this.project) {
      //@ts-ignore
      if (this.project.showMilestones) {
        let viewDays;
        //Change the viewDays depending on if today is in this view or not
        if (this.viewEnd.getTime() > this.today) {
          // set the viewDays to be equals to the amount of days from the view start to today
          viewDays = this.roadMapHelperService.daysInPeriod(new Date(this.today), this.viewStart, false)
        }
        else {
          // set the viewDays to be equals to the amount of days from the view start to view end
          viewDays = this.roadMapHelperService.daysInPeriod(this.viewEnd, this.viewStart, false)
        }
        //Calculate the viewWidth depending on the viewDays
        var viewWidth = viewDays * this.cellWidth;
        for (var l = 1; l <= this.maxLinesOfEvents; l++) {
          this.linesFill[l] = [];
          for (var ld = 0; ld <= this.viewPeriod; ld++) {
            this.linesFill[l].push(false);
          }
        }
        this.project.milestones.forEach(event => {
          var evt = (Object.assign({}, event) as any);
          var eStart = new Date(evt.startDate).getTime(),
            eEnd = new Date(evt.endDate).getTime(),
            viewRealStart = new Date(this.viewStart.getTime()),
            viewRealEnd = new Date(this.viewEnd.getTime());
          viewRealStart.setHours(0);
          viewRealStart.setMinutes(0);
          viewRealStart.setSeconds(0);
          viewRealEnd.setHours(23);
          viewRealEnd.setMinutes(59);
          viewRealEnd.setSeconds(59);
          var vStart = viewRealStart.getTime(),
            vEnd = viewRealEnd.getTime();
          if (eStart < vStart && eEnd < vStart) // Do not render event if it's totally BEFORE the view's period
            return true;
          if (eStart > vEnd) // Do not render event if it's AFTER the view's period
            return true;
          
          // Calculate the left and width offsets for the event's element
          var offsetDays = -this.roadMapHelperService.daysInPeriod(new Date(evt.startDate), this.viewStart, true);
          var eventLength =
            this.roadMapHelperService.daysInPeriod(new Date(evt.endDate), new Date(evt.startDate), false) + 1;

          var eventWidth = eventLength * this.cellWidth;
          var offsetLeft = Math.floor(offsetDays * this.cellWidth);


          var daysExceed = 0, extraClass = evt.type + ' ';
          // If the event's START date is BEFORE the current displayed view
          if (offsetDays < 0) {
            offsetLeft = 0; // to stick the element to extreme left
            daysExceed = -offsetDays; // to trim the total element's width
            extraClass += 'overRight '; // to decorate the element's left boundary
          } else {
            extraClass += 'overLeft ';
          }
          var todayWithoutTime = new Date().setHours(0, 0, 0, 0);
          if (this.isLoggedIn) {
            extraClass += ' cursor-pointer ';
          }
          // If the event's END date is AFTER the current displayed view
          if (eEnd > vEnd) {
            daysExceed = this.roadMapHelperService.daysInPeriod(this.viewEnd, new Date(evt.endDate), false);
            extraClass += 'overLeft '; // to decorate the element's right boundary
          } else {
            // if the event is begun and ended in the current view we add a .begun-and-ended class
            if (eEnd < todayWithoutTime && eStart > this.viewStart.setHours(0, 0, 0, 0)) {
              extraClass += 'begun-and-ended '
            }
            extraClass += 'overRight ';
          }
          // Since events can be longer than the today we se vEnd to today if its bigger than today
          if (this.today < vEnd) {
            vEnd = todayWithoutTime;
          }
          // calculates the real event width
          // If the width exceeds the viewWidth in both ends we add the .exceeded-in-both-ends class to changes the styling of the event
          // If not it calculates the width normally
          let realEventWidth;
          if (eEnd >= vEnd && offsetDays < 0) {
            realEventWidth = viewWidth + 'px';
            extraClass += 'exceeded-in-both-ends ';
          } else {
            realEventWidth = (eventWidth - ((daysExceed + 1) * this.cellWidth)) + 'px'
          }

          if (evt.mileStoneType == 1) {
            extraClass += 'kravspecifikation ';
          }
          if (evt.mileStoneType == 2) {
            extraClass += 'programmering ';
          }
          if (evt.mileStoneType == 3) {
            extraClass += 'pilot ';
          }
          if (evt.mileStoneType == 4) {
            extraClass += 'release ';
          }

          if (eStart <= this.currTime && eEnd >= this.currTime) {
            extraClass += 'current ';
          }
          if ((eventWidth - ((daysExceed + 1) * this.cellWidth)) < 100) {
            extraClass += 'small-width-events ';
          }
          evt.extraClasses = extraClass;

          evt.line = 1;
          var listDays = [];
          for (var n = 0; n < eventLength; n++) {
            var D = this.roadMapHelperService.addDaysToDate(evt.startDate, n);
            var thisDayList = this.enumDays.filter(x => x.time == D.getTime());
            if (!thisDayList && thisDayList.length < 1) continue;

            var thisDay = thisDayList[0];
            if (!thisDay) continue;

            listDays.push(thisDay);
          }
          for (var i = 0; i < this.linesFill.length; i++) {
            var thisLine = this.linesFill[i];
            if (thisLine) {
              if (listDays.every(x => thisLine[x.offset] == false)) {
                listDays.forEach(x => thisLine[x.offset] = x.num + ': ' + evt.name);
                break;
              } else {
                evt.line += 1;
                continue;
              }
            }
          }       
          evt.locScale = {
            'position': 'absolute',
            'left': Math.floor(offsetLeft) + 'px',
            'width': realEventWidth,
            'top': (evt.line * ((this.eventHeight + this.eventMargin)) + this.element.nativeElement.offsetTop + 60) +
              'px',
            'height': this.eventHeight + 'px',
          };
          this.project.renderedEvents.push(evt);
        });
  
        var filledLines = 0;
        for (var l = 1; l <= this.maxLinesOfEvents; l++) {
          for (var ld = 0; ld <= this.viewPeriod; ld++) {
            if (this.linesFill[l][ld] !== false) {
              filledLines += 1;
              break;
            }
          }
        }
        const projectMarginBottom = 20;
        const projectHeight = ((filledLines) * (this.eventHeight + this.eventMargin));
        this.projectHeight = { 'height': + (projectHeight - this.eventMargin) + projectMarginBottom + 'px' }
      }
    }

  }

  editProject(project) {
    const onSuccess = () => this.roadmapService.updateProject(project)
    this.modalService.open(CreateProjectComponent, {
      project: project,
      isEditing: true
    }, undefined, onSuccess, this.overlayStyling);
  }

  editMilestone(milestone, project) {
    if (this.isLoggedIn) {
      const onSuccess = () => {
        if (milestone.startDate) {
          milestone.startDate = new Date(milestone.startDate);
        }
        if (milestone.endDate) {
          milestone.endDate = new Date(milestone.endDate);
        }
        let actualMileStone = {
          uId: milestone.uId,
          name: milestone.name,
          mileStoneType: milestone.mileStoneType,
          startDate: this.dateFilter.transform(milestone.startDate, "yyyy-MM-dd"),
          endDate: milestone.hasEndDate ? this.dateFilter.transform(milestone.endDate, "yyyy-MM-dd") : undefined,
          progress: !isNaN(milestone.progress) ? parseInt(milestone.progress) : 0
        }

        this.editMilestoneInProject(actualMileStone, project);
      }
      milestone.startDate = this.dateFilter.transform(milestone.startDate, "yyyy-MM-dd"),
        milestone.endDate = this.dateFilter.transform(milestone.endDate, "yyyy-MM-dd"),
        this.modalService.open(EditMilestoneComponent, milestone, undefined, onSuccess, this.overlayStyling);
    }
  }

  addMilestoneToProject(project) {
    if (this.isLoggedIn) {
      const milestone = ({} as any);
      const onSuccess = () => {
        if (milestone.startDate) {
          milestone.startDate = new Date(milestone.startDate);
        }
        if (milestone.endDate) {
          milestone.endDate = new Date(milestone.endDate);
        }
        let actualMileStone = {
          uId: milestone.uId,
          name: milestone.name,
          mileStoneType: milestone.mileStoneType,
          startDate: this.dateFilter.transform(milestone.startDate, "yyyy-MM-dd"),
          endDate: milestone.hasEndDate ? this.dateFilter.transform(milestone.endDate, "yyyy-MM-dd") : undefined,
          progress: !isNaN(milestone.progress) ? parseInt(milestone.progress) : 0
        }
        this.saveMilestoneToProject(actualMileStone, project);
      }
      this.modalService.open(CreateMilestoneComponent, milestone, undefined, onSuccess, this.overlayStyling);
    }
  }
  editMilestoneInProject(milestone, project) {
    this.roadmapService.updateMilestone(milestone);
  }

  saveMilestoneToProject(milestone, project: Project) {
    this.roadmapService.addMilestoneToProject(milestone, project.uId);
  }

  isLoggedIn: boolean;
}



