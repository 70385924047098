import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class RoadMapHelperService {


  daysInMonth(date: Date) {
    let r = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    return parseInt(r.toString());
  }
  daysInPeriod(date1: Date, date2: Date, wantDiff: boolean) {
    date1 = new Date(date1);
    date2 = new Date(date2);
    let one_day = 1000 * 60 * 60 * 24;
    date1.setHours(12); date1.setMinutes(0); date1.setSeconds(0); date1.setMilliseconds(0);
    date2.setHours(12); date2.setMinutes(0); date2.setSeconds(0); date2.setMilliseconds(0);
    let result = parseInt(((date2.getTime() - date1.getTime()) / one_day).toString());
    if (wantDiff) return result;
    else return Math.abs(result);
  }
  addDaysToDate(date: Date, days: number) {
    let ndate = new Date(date);
    let mdate = new Date(ndate.getTime());
    mdate.setTime(mdate.getTime() + days * 1000 * 60 * 60 * 24);
    mdate.setHours(12); mdate.setMinutes(0); mdate.setSeconds(0); mdate.setMilliseconds(0);
    return mdate;
  }
  listHoursInDay(stardDayHour: number, endDayHour: number) {
    let enumHours = [];
    for (var h = stardDayHour; h < (endDayHour + 1); h++)
      enumHours.push({ num: h, title: ('00' + h).substr(-2) });
    return enumHours;
  }

  setUpDays(date: Date, backwards: boolean) {
    let amountOfdays = 0;
    for (var i = 0; i < 5; i++) {
      amountOfdays = + this.daysInMonth(new Date(date.setMonth(backwards ? -1 : 1)))
    }
    return amountOfdays;
  }
  addHoursToDate(date: Date, hours: number) {
    let mdate = new Date(new Date(date).getTime());
    mdate.setTime(mdate.getTime() + hours * 1000 * 60 * 60);
    mdate.setMinutes(0); mdate.setSeconds(0); mdate.setMilliseconds(0);
    return mdate;
  }
  between(z, a, b) {
    var min = Math.min.apply(Math, [a, b]),
      max = Math.max.apply(Math, [a, b]);
    return z > min && z < max;
  };
}
