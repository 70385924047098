import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from "incom-login";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  routeSub$: Subscription;
  currentRoute: string;
  notifier = new Subject();
  constructor(private router: Router, private authenticationService: AuthenticationService) {

  }
  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
    this.routeSub$.unsubscribe();
  }
  ngOnInit(): void {
    this.authenticationService.currentUser.pipe(takeUntil(this.notifier)).subscribe(x => {
      this.isLoggedIn = !!x;
    })
    this.routeSub$ = this.router.events.subscribe(event => {
      if (event && event instanceof ActivationEnd) {
        this.currentRoute = event.snapshot.url[0].path;
      }
    });
  }
  onNavigate(location) {
    if (location) {
      this.router.navigate([location]);
    }
  }
  logOut() {
    this.authenticationService.logout(false);
  }
  title = 'app';
  isLoggedIn: boolean;
}
