import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { RoadMapStateService } from '../service/roadmap-state.service';
import { Project } from "../Timeline/Project";
import { Subject } from "rxjs"

@Component({
  selector: 'app-inprogress',
  templateUrl: './inprogress.component.html',
  styleUrls: []
})
export class InProgressComponent implements OnInit, OnDestroy {
  projects: Project[];
  notifier = new Subject();
  constructor(private roadmapService: RoadMapStateService) {

  }
  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }
  ngOnInit(): void {
    this.roadmapService.setProjectType(2);
    this.getProjects();
  }
  getProjects() {
    this.roadmapService.getProjects().pipe(takeUntil(this.notifier)).subscribe(x => {
      this.projects = x.sort((a, b) => (a.priority < b.priority) ? 1 : -1);
    });
  }
}
