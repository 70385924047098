import { Component } from "@angular/core";
import { ModalBase } from "node_modules/incom-component-library/";
import { Subject } from "rxjs";
import { Project } from "../../Timeline/Project";

@Component({
  selector: "modal-project",
  templateUrl: "./createproject.modal.html",
  host: {
    class: "flex h-full flex-col",
  },
})
export class CreateProjectComponent implements ModalBase {
  scrollStrategy: undefined | "noop" | "block" | 'reposition' = 'block';
    project: Project;
  constructor() { }

  ngOnInit(): void {
    this.project = this.data.project;
    this.isEditing = this.data.isEditing;
  }





  close() {
    this.closed$.next(false);
    this.onClose();
  }

  submit(form) {
    this.closed$.next(true);
    this.onSuccess();
  }
  releases = [
    {
    name: "Pipeline",
    id: 1,
    },
    {
      name: "Igangværende",
      id: 2,
    },
    {
      name: "released",
      id: 3,
    }
    ];
  onClose: Function;
  onSuccess: Function;
  data: { project: Project, isEditing: boolean };
  closed$: Subject<boolean> = new Subject;
  isEditing: boolean;
}
