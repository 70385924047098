import { Component } from "@angular/core";
import { ModalBase } from "node_modules/incom-component-library/";
import { Subject } from "rxjs";
import { Milestone } from "../../Timeline/Milestone";
import { RoadMapStateService } from "../../service/roadmap-state.service";

@Component({
  selector: "modal-start-project",
  templateUrl: "./startproject.modal.html",
  host: {
    class: "flex h-full flex-col",
  },
})
export class StartProjectComponent implements ModalBase {
  scrollStrategy: undefined | "noop" | "block" | 'reposition' = 'block';
  constructor(private roadmapStateService: RoadMapStateService) { }

  ngOnInit(): void {

  }

  delete() {
    //@ts-ignore
    this.roadmapStateService.deleteProject(this.data.projectId);
    this.closed$.next(false);
    this.onClose();
  }

  milestoneTypes = [{
      name: "Kravspecifikation",
      type: 1
    },
    {
      name: "Programmering",
      type: 2
    },
    {
      name: "Pilot",
      type: 3
    },
    {
      name: "Release",
      type: 4
    }
  ];

  close() {
    this.closed$.next(false);
    this.onClose();
  }

  submit(form) {
    this.closed$.next(true);
    this.onSuccess();
  }

  onClose: Function;
  onSuccess: Function;
  data: Milestone;
  closed$: Subject<boolean> = new Subject;
  isEditing: boolean;
}
