import { DatePipe } from '@angular/common';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ModalService } from 'incom-component-library';
import { RoadMapStateService } from '../service/roadmap-state.service';
import { RoadMapHelperService } from "../service/roadmap-helper.service";
import { Project } from "./Project";
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from "incom-login";

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit, OnChanges {
  @Input("projects") projects: Project[];
  @Input("projectType") projectType: string;
  @Input("startMonth") startMonth: number;
  @Input("endMonth") endMonth: number;
  overlayStyling: string[] = [
    "incom-modal-overlay"
  ];
  @ViewChild('tbody', { static: true }) tbody: ElementRef;
  resize$ = new Subject<void>();

  constructor(private dateFilter: DatePipe, private roadmapService: RoadMapStateService, private modalService: ModalService, private roadMapHelperService: RoadMapHelperService, private authenticationService: AuthenticationService) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.renderView();
  }
  ngOnInit(): void {
    this.authenticationService.currentUser.pipe(takeUntil(this.notifier)).subscribe(x => {
      this.isLoggedIn = !!x;
    })
    this.renderView();
    this.resize$
      .pipe(
        debounceTime(300)
      )
      .subscribe(() => {
        this.renderView();
      });
  }
  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }
  @HostListener("window:resize", ["$event.target"])
  onResize():void {
    this.resize$.next();
  }
  prioUp(index: number) {
    var previousItem = this.projects[index - 1];
    var item = this.projects[index];
    previousItem.priority = item.priority;
    item.priority = previousItem.priority;
    this.projects[index - 1] = item;
    this.projects[index] = previousItem;
    this.roadmapService.updateProject(item);
    this.roadmapService.updateProject(previousItem);
  }
  prioDown(index: number) {
    var previousItem = this.projects[index + 1];
    var item = this.projects[index];
    previousItem.priority = item.priority;
    item.priority = previousItem.priority;
    this.projects[index + 1] = item;
    this.projects[index] = previousItem;
    this.roadmapService.updateProject(item);
    this.roadmapService.updateProject(previousItem);
  }
  drop(event: CdkDragDrop<string[]>) {
    if (this.isLoggedIn) {
      moveItemInArray(this.projects, event.previousIndex, event.currentIndex);
      let indexer = 0;
      for (var i = this.projects.length; i > 0; i--) {
        const project = this.projects[indexer];
        if (project.priority != i) {
          project.priority = i;
          this.roadmapService.updateProject(project);
        }
        indexer++;
      }
    }


  }
  now = new Date();

  // Today's date
  currDate = this.roadMapHelperService.addDaysToDate(new Date(), 0);
  shownDate = new Date(this.currDate.getTime());
  viewStart;
  viewEnd;

  formatDayLong = 'EEEE MMMM dd';
  formatDayShort = 'yyyy-MM-dd';
  formatMonth = 'MMM';
  formatYear = 'yyyy';
  dayStartHour = 8;
  currTime = this.currDate.getTime();

  dayEndHour = 20;
  nbHours = this.dayEndHour + 1 - this.dayStartHour;
  minCellWidthForHours = (this.dayEndHour + 1 - this.dayStartHour) * 13;
  enumDays = [];
  enumMonths = [];

  gridWidth = 2000;
  viewPeriod;
  cellWidth;
  HcellWidth;
  linesFill: any = {};



  renderView(dateToSetFrom?: Date) {
    this.setView(!!dateToSetFrom ? dateToSetFrom : this.shownDate);
    this.enumDays = [];
    this.enumMonths = [];
    this.linesFill = [];
    this.gridWidth = this.tbody.nativeElement.offsetWidth;
    this.viewPeriod = this.roadMapHelperService.daysInPeriod(this.viewStart, this.viewEnd, false);
    this.cellWidth = this.gridWidth / (this.viewPeriod + 1);
    this.HcellWidth = this.cellWidth / this.nbHours;
    var lastMonth = -1, monthNumDays = 1, nbMonths = 0;
    for (var d = 0; d <= this.viewPeriod; d++) {
      var dayDate = this.roadMapHelperService.addDaysToDate(this.viewStart, d);
      var today = (this.currDate.getTime() === dayDate.getTime());
      var isLastOfMonth = (this.roadMapHelperService.daysInMonth(dayDate) === dayDate.getDate());
      this.enumDays.push({
        num: this.dateFilter.transform(dayDate, 'dd'),
        offset: d,
        date: dayDate,
        time: dayDate.getTime(),
        title: this.dateFilter.transform(dayDate, this.formatDayLong),
        nbEvents: 0,
        today: today,
        isLastOfMonth: isLastOfMonth,
        enumHours: this.roadMapHelperService.listHoursInDay(this.dayStartHour, this.dayEndHour)
      });
      // Populate the list of all months
      monthNumDays += 1;
      if (lastMonth != dayDate.getMonth()) {
        this.enumMonths.push({
          num: dayDate.getMonth(),
          name: this.dateFilter.transform(dayDate, this.formatMonth),
          year: this.dateFilter.transform(dayDate, this.formatYear)
        });
        lastMonth = dayDate.getMonth();
        monthNumDays = 1;
        nbMonths += 1;
      }
      if (this.enumMonths[nbMonths - 1]) {
        this.enumMonths[nbMonths - 1].numDays = monthNumDays;
      }
    }
    this.roadmapService.renderEvent$.next(true);
  }


  prevDay() {
    this.viewStart = this.roadMapHelperService.addDaysToDate(this.viewStart, -1);
    this.viewEnd = this.roadMapHelperService.addDaysToDate(this.viewEnd, -1);
    this.renderView();
  };
  /*
   * Offset view to previous X days
   */
  prevCustom() {
    this.shownDate = new Date(this.shownDate.setMonth(this.shownDate.getMonth() - Math.round(this.monthsToShow / 2)));
    this.renderView(this.shownDate);
  };
  /*
   * Set the start date for view
   */
  setStartView(year, month, day) {
    var date = this.roadMapHelperService.addDaysToDate(new Date(year, month - 1, day), 0);
    if (date.getTime() >= this.viewEnd.getTime()) {
      /*   this.throwError(2, "Aborting view draw: start date would be after end date.");*/
      return;
    }
    this.viewStart = date;
    this.renderView();
  };
  /*
   * Zoom IN view (-1 day on each side)
   */
  zoomIn(step) {
    if (this.roadMapHelperService.daysInPeriod(this.viewStart, this.viewEnd, false) <= 2) {
      /*   this.throwError(2, "Aborting view draw: reached minimum days to show.");*/
      return;
    }
    this.viewStart = this.roadMapHelperService.addDaysToDate(this.viewStart, +step);
    this.viewEnd = this.roadMapHelperService.addDaysToDate(this.viewEnd, -step);
    this.renderView();
  };
  /*
   * Zoom OUT view (+1 day on each side)
   */
  zoomOut(step) {
    if (this.roadMapHelperService.daysInPeriod(this.viewStart, this.viewEnd, false) >= 365) {
      /*      this.throwError(2, "Aborting view draw: reached maximum days to show.");*/
      return;
    }
    this.viewStart = this.roadMapHelperService.addDaysToDate(this.viewStart, -step);
    this.viewEnd = this.roadMapHelperService.addDaysToDate(this.viewEnd, +step);
    this.renderView();
  };
  /*
   * Center view to current day (defaults -7, +14 days)
   */
  centerView(daysBefore, daysAfter) {
    if (typeof daysBefore === 'undefined') daysBefore = 7;
    if (typeof daysAfter === 'undefined') daysAfter = 14;
    this.viewStart = this.roadMapHelperService.addDaysToDate(new Date(), -daysBefore);
    this.viewEnd = this.roadMapHelperService.addDaysToDate(new Date(), daysAfter);
    this.renderView();
  };
  /*
   * Offset view to next day
   */
  nextDay() {
    this.viewStart = this.roadMapHelperService.addDaysToDate(this.viewStart, 1);
    this.viewEnd = this.roadMapHelperService.addDaysToDate(this.viewEnd, 1);
    this.renderView();
  };
  /*
   * Offset view to next X days
   */
  nextCustom() {
    this.shownDate = new Date(this.shownDate.setMonth(this.shownDate.getMonth() + Math.round(this.monthsToShow / 2)));
    this.renderView(this.shownDate);
  };
  /*
   * Set the end date for view
   */
  setEndView(year, month, day) {
    var date = this.roadMapHelperService.addDaysToDate(new Date(year, month - 1, day), 0);
    if (date.getTime() <= this.viewStart.getTime()) {
      /*     this.throwError(2, "Aborting view draw: end date would be before start date.");*/
      return;
    }
    this.viewEnd = date;
    this.renderView();
  };



  setView(dateToSetFrom: Date) {
    this.calculateMonthsToShow();
    let beforeToShow = 0;
    for (let i = 1; i <= this.monthsToShow; i++) {
      beforeToShow += new Date(dateToSetFrom.getFullYear(), dateToSetFrom.getMonth() - i, 0).getDate();
    }
    this.viewStart = this.roadMapHelperService.addDaysToDate(dateToSetFrom, -beforeToShow);
    const daysUntilMonthsEnd = new Date(dateToSetFrom.getFullYear(), dateToSetFrom.getMonth() + 1, 0).getDate() - dateToSetFrom.getDate();
    this.viewEnd = this.roadMapHelperService.addDaysToDate(dateToSetFrom, daysUntilMonthsEnd);
    this.viewPeriod = this.roadMapHelperService.daysInPeriod(this.viewStart, this.viewEnd, false);
    this.cellWidth = this.gridWidth / (this.viewPeriod + 1);
    this.HcellWidth = this.cellWidth / this.nbHours;
  }
  calculateMonthsToShow() {
    if (window.innerWidth < 200) {
      this.monthsToShow = 0;
    }
    this.monthsToShow = Math.round(window.innerWidth / 200);
  }

  monthsToShow: number;
  isLoggedIn: boolean;
  notifier = new Subject();
}
