import { Injectable } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs"
import { take } from "rxjs/operators";
import { RoadMapHttpService } from "./roadmap-http.service";

@Injectable({
  providedIn: "root"
})
export class RoadMapStateService {
  private internalActions$ = new ReplaySubject<any[]>(1);
  actions$ = this.internalActions$.asObservable();
  private internalProjects$ = new Subject<any[]>();
  projects$ = this.internalProjects$.asObservable();
  projectType: number;
  renderEvent$ = new Subject();
  constructor(private roadmapHttpService: RoadMapHttpService) {
  }
  addMilestoneToProject(milestone, id: string) {
    this.roadmapHttpService.addMilestoneToProject(milestone, id).pipe(take(1)).subscribe(x => {
      if (x) {
        this.roadmapHttpService.getProjects(this.projectType).pipe(take(1)).subscribe(x => {
          this.internalProjects$.next(x);
        });
      }
    });
  }
  createProject(project) {
    return this.roadmapHttpService.createProject(project).pipe(take(1)).subscribe(x => {
      if (x) {
        this.roadmapHttpService.getProjects(this.projectType).pipe(take(1)).subscribe(x => {
          this.internalProjects$.next(x);
        });
      }
    });
  }
  getMileStonesForProject(projectUId) {
    this.roadmapHttpService.getMileStonesForProject(projectUId);
  }
  getProjects() {

    this.roadmapHttpService.getProjects(this.projectType).pipe(take(1)).subscribe(x => {
      this.internalProjects$.next(x);
    });
    return this.projects$;
  }
  updateMilestone(milestone) {
    this.roadmapHttpService.updateMilestone(milestone).pipe(take(1)).subscribe(x => {
      if (x) {
        this.roadmapHttpService.getProjects(this.projectType).pipe(take(1)).subscribe(x => {
          this.internalProjects$.next(x);
        });
      }
    });
  }
  updateProject(project) {
    this.roadmapHttpService.updateProject(project).pipe(take(1)).subscribe(x => {
      if (x) {
        this.roadmapHttpService.getProjects(this.projectType).pipe(take(1)).subscribe(x => {
          this.internalProjects$.next(x);
        });
      }
    });
  }
  setProjectType(type) {
    this.projectType = type;
  }
  deleteMileStone(uId) {
    this.roadmapHttpService.deleteMilestone(uId).pipe(take(1)).pipe(take(1)).subscribe(x => {
      if (x) {
        this.roadmapHttpService.getProjects(this.projectType).pipe(take(1)).subscribe(x => {
          this.internalProjects$.next(x);
        });
      }
    });
  }
  deleteProject(uId) {
    this.roadmapHttpService.deleteProject(uId).pipe(take(1)).pipe(take(1)).subscribe(x => {
      if (x) {
        this.roadmapHttpService.getProjects(this.projectType).pipe(take(1)).subscribe(x => {
          this.internalProjects$.next(x);
        });
      }
    });
  }
}

