import { Milestone } from "./Milestone";

export class Project {
  id: number;
  name: string;
  milestones: Milestone[];
  priority?: number;
  uId?: string;
  renderedEvents: any[];
  showMilestones: boolean;
  productOwner: string;
  customerProductOwner: string;
  projectPO: string;
}
