import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop'
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { TimelineComponent } from './Timeline/timeline.component';
import { DatePipe } from '@angular/common';
import { PipelineComponent } from './pipeline/pipeline.component';
import { CreateProjectComponent } from './pipeline/Modal/createProject.modal';
import { BEXComponentDesignModule } from 'incom-component-library';
import { StartProjectComponent } from './pipeline/Modal/startProject.modal';
import { EditMilestoneComponent } from './milestone/editMilestone.modal';
import { CreateMilestoneComponent } from './milestone/createMilestone.modal';
import { MaterialExampleModule } from './material.module';
import { ProjectComponent } from "./Timeline/project/project.component";
import { AuthGuard, LoginComponent, LoginSharedModule, ResetPasswordComponent } from "incom-login";
import { InProgressComponent } from "./inprogress/inprogress.component";
import { ReleaseComponent } from "./release/release.component";
const routes: Routes = [
  { path: "*", redirectTo: "/" },
  { path: '', component: ReleaseComponent },
  { path: "released", component: ReleaseComponent },
  { path: "inprogress", component: InProgressComponent },
  { path: "pipeline", component: PipelineComponent },
  { path: 'login', canActivate: [AuthGuard], component: LoginComponent, pathMatch: 'full' },
  { path: 'reset-password', canActivate: [AuthGuard], component: ResetPasswordComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    InProgressComponent,
    ReleaseComponent,
    PipelineComponent,
    CreateProjectComponent,
    TimelineComponent,
    StartProjectComponent,
    CreateMilestoneComponent,
    EditMilestoneComponent,
    ProjectComponent

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    BEXComponentDesignModule,
    FormsModule,
    DragDropModule,
    LoginSharedModule.withAuthInterceptorAndRoutes(),
    MaterialExampleModule,
    RouterModule.forRoot(routes)
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
  entryComponents: [CreateProjectComponent, StartProjectComponent, CreateMilestoneComponent, EditMilestoneComponent],
})
export class AppModule {

}
