import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { RoadMapStateService } from '../service/roadmap-state.service';
import { Subject } from "rxjs"
import { Project } from "../Timeline/Project";


@Component({
  selector: 'app-release',
  templateUrl: './release.component.html',
  styleUrls: []
})
export class ReleaseComponent implements OnInit, OnDestroy {
  notifier = new Subject();
  projects: Project[];
  constructor(private roadmapService: RoadMapStateService) {

  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  ngOnInit(): void {
    this.roadmapService.setProjectType(3);
    this.getProjects();

  }
  getProjects() {
    this.roadmapService.getProjects().pipe(takeUntil(this.notifier)).subscribe(x => {
      this.projects = x;
    });
  }
}
